import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })

export class OfferingService {
  constructor(private http: HttpClient) { }
  getOffering(idOffering) { return this.http.get(`${environment.apiUrl}/offering/${idOffering}?collect=produk,client,travelPaket,clientOrder`); }
  getOffering2(where) { return this.http.get(`${environment.apiUrl}/offering/?collect=produk,client,travelPaket,clientOrder&where=${JSON.stringify(where)}`); }
  getOrder(id) { return this.http.get(`${environment.apiUrl}/order/${id}?collect=produk,client,travelPaket`); }
  getClient() { return this.http.get(`${environment.apiUrl}/client?limit=10000`); }
  getPackage() { return this.http.get(`${environment.apiUrl}/travelPaket?collect=produk`); }
  getProduct() { return this.http.get(`${environment.apiUrl}/dataoffering/get-product`); }
  postOffering(dataPost) { return this.http.post(`${environment.apiUrl}/travel/create-penawaran`, dataPost); }
  postOrdering(dataPost) { return this.http.post(`${environment.apiUrl}/travel/create-order`, dataPost); }
  approveOffering(dataPost) { return this.http.post(`${environment.apiUrl}/dataoffering/approve`, dataPost); }
  // tslint:disable-next-line:max-line-length
  // getHotel() { return this.http.get(`${environment.apiUrl}/vendorproduk?where=${JSON.stringify({ typeProduk: 3, deleted: 0 })}`); } // , id: { in: [40, 42] }
  getHotel() { return this.http.get(`${environment.apiUrl}/masterData?parent=1&limit=10000`); }
  // tslint:disable-next-line:max-line-length
  // getVisa() { return this.http.get(`${environment.apiUrl}/vendorproduk?where=${JSON.stringify({ typeProduk: 5, deleted: 0 })}`); } // , id: { in: [38] }
  getVisa() { return this.http.get(`${environment.apiUrl}/offeringMaster?category=VISA&status=enabled&sort=sorting`); }
  getFlight() { return this.http.get(`${environment.apiUrl}/offeringMaster?category=FLIGHT&status=enabled&sort=sorting`); }
  getHo() { return this.http.get(`${environment.apiUrl}/offeringMaster?category=HO&status=enabled&sort=sorting&limit=100`); }

  addOffering(dataPost) { return this.http.post(`${environment.apiUrl}/offering`, dataPost); }
  updateOffering(dataPost) { return this.http.put(`${environment.apiUrl}/offering/${dataPost.id}`, dataPost); }

  sartousd() { return this.http.get(`${environment.apiUrl}/config/36`); }
  usdtoidr() { return this.http.get(`${environment.apiUrl}/config/35`); }

  postOfferingProduct(dataPost) { return this.http.post(`${environment.apiUrl}/offeringProduct`, dataPost); }
  offeringProduct(where) { return this.http.get(`${environment.apiUrl}/offeringProduct?sort=rangePax&where=${JSON.stringify(where)}`); }
  updateOfferingProduct(dataPost) { return this.http.put(`${environment.apiUrl}/offeringProduct/${dataPost.id}`, dataPost); }
  getUser(idUser) { return this.http.get(`${environment.apiUrl}/user/${idUser}`); }
}
