import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })

export class ClientService {
  constructor(private http: HttpClient) { }
  getHotel() { return this.http.get(`${environment.apiUrl}/masterData?parent=1&deleted=0&limit=10000`); }
  addClientOrder(dataPost) { return this.http.post(`${environment.apiUrl}/clientorder/submit`, dataPost); }
  updateClientOrder(dataPost) { return this.http.put(`${environment.apiUrl}/clientorder/${dataPost.id}`, dataPost); }
  clientOrderById(id) { return this.http.get(`${environment.apiUrl}/clientorder/${id}`); }
  clientOrderDetail(where) { return this.http.get(`${environment.apiUrl}/clientorderitems?where=${JSON.stringify(where)}`); }
}
