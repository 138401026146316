import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() {}
  
  doPrint(printEl: any) {
    let printSection: HTMLElement = document.querySelector('#printSection')

    if (!printSection) {
      printSection = document.createElement('div')
      printSection.id = 'printSection'
      document.body.appendChild(printSection)
    } 

    printSection.innerHTML = ''

    let elm: HTMLElement = document.querySelector(`#${printEl}`)

    let elementCopy = elm.cloneNode(true)
    printSection.appendChild(elementCopy)

    window.print()
  }
}
